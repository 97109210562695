/*
 * @Author: your name
 * @Date: 2022-01-25 14:42:33
 * @LastEditTime: 2022-01-25 14:44:29
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \zjyd-gonggong-front-wenjuan-mobile\src\config\secret.js
 */
export let baseURL = 'https://gw.cveducloud.com';
export let domain = ''; // 主域名
export let loginUrl = ''; //登录中心

export const setBaseUrl = (url) => {
    baseURL = url;
};

export const setDomain = (url) => {
    domain = url;
};

export const setLoginUrl = (url) => {
    loginUrl = url;
};
// 依赖07接口
export const initSecretUrlByType07 = (config) => {
    setBaseUrl(config?.gw ?? '');
    let domain = config?.gw
        ?.split('.')
        .reverse()
        .slice(0, 2)
        .reverse()
        .join('.');
    setDomain(domain ? `.${domain}` : '');
    setLoginUrl(config?.sys?.logincenter ?? '');
};
