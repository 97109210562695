import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
// import './element-variables.scss'
import { initSecretUrlByType07, baseURL} from "./config/secret";
import { getImageOssBaseurl  } from "./api/Baseurl";
import Http from './utils/request';
Vue.config.productionTip = false
Vue.use(Element)
// 图片预览
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

var option = {
  maxSpreadZoom: 1, // 控制预览图最大的倍数，默认是2倍，我这里改成了原图
  fullscreenEl: false, //控制是否显示右上角全屏按钮
  closeEl: false, //控制是否显示右上角关闭按钮
  tapToClose: true, //点击滑动区域应关闭图库
  shareEl: false, //控制是否显示分享按钮
  zoomEl: false, //控制是否显示放大缩小按钮
  counterEl: true, //控制是否显示左上角图片数量按钮
  arrowEl: true,  //控制如图的左右箭头（pc浏览器模拟手机时）
  tapToToggleControls: true, //点击应切换控件的可见性
  clickToCloseNonZoomable: true //点击图片应关闭图库，仅当图像小于视口的大小时
}

Vue.use(preview, option)


function create(src) {
  return function (path) {
    if (!path) return;
    if (path.indexOf("/") === 0) {
      return src + path;
    } else {
      return src + path;
    }
  };
}


getImageOssBaseurl({ type: '100007' }).then(res => {
  const {
    'static-img': staticImg,
    'front-img': frontImg,
    'public-resource': publicResource,
  } = res.result.config
  Vue.prototype.$$setImgSrc = create(staticImg);
  Vue.prototype.$$uploadRc = create(frontImg);
  Vue.prototype.$$img = create(publicResource);
  if (res ?.result ?.config) {
    initSecretUrlByType07(res ?.result ?.config);
    Http.defaults.baseURL = baseURL;
  }
})
// 获取url的token
function getQuery(name) {
  const search = window.location.href.split('?')[1];
  if (!search) return null;
  const item = search.split("&");
  if (item) {
    for (var i = 0; i < item.length; i++) {
      var pair = item[i].split("=");
      if (pair[0] == name) {
        return pair[1];
      }
    }
  } else {
    console.log(`${name} is not in search`);
    return null;
  }
}
window.token = getQuery('token')
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
