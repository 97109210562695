// 答题页面限制答题次数等，header信息
export const limitAnswerCount  = (url)=>{
    let temHead = localStorage.getItem('__________cccccckkkkkkkkkkk_______');
    if(temHead && url.indexOf('api/wenjuan/api/v1/answer/submitQuestion')>-1){
        return {
            '__________cccccckkkkkkkkkkk_______': temHead
        }
    }else if(url.indexOf('api/wenjuan/api/v1/answer/submitQuestion')>-1){
        return {
            '__________cccccckkkkkkkkkkk_______': ''
        }
    }else{
        return '';
    }
}