
import axios from "axios"

import { setToken } from "./token";
import md5 from "js-md5";

import { isEmpty } from './isEmpty';

import Cookies from 'js-cookie';
import { baseURL } from '../config/secret.js';
import { limitAnswerCount } from './extendHeader.js'

const Http = axios.create({
    timeout: 15000,
    withCredentials: false,
    baseURL
})

Http.interceptors.request.use(config => {
    let now = new Date().getTime();

    // const u = navigator.userAgent;
    // const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端或者uc浏览器
    // const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    // // 获取token
    // let token = ''
    // if (isAndroid) {
    //     token = window.android.getToken()
    // } else if (isiOS) {
    //     window.setToken = function(obj){
    //         token = obj
    //     }
    // }

    // let info = Cookies.get('info'),
    //     token = null;
    // if (info) {
    //     token = JSON.parse(info).token;
    // }
    
    // let token="eyJhbGciOiJIUzUxMiJ9.eyJMT0dJTl9UWVBFIjoiUEMiLCJpZGVudGl0eU5hbWUiOiLogIHluIgiLCJ1c2VyX2lkIjoiNDViNGY4OGFmN2E2NGMxOThlMTFmZTgyYTA1ZDdhODUiLCJpZGVudGl0eUlkIjoiNzIiLCJvcmduaXphdGlvbnMiOiJjYjgyNzE0N2EyMmQ0YzYzOTE3MGY0YmI1NWI0ZDI1ZCIsIlVTRVJfTkFNRSI6IjE1ODQ0NDQ0MzQ3IiwibG9naW5fdXNlcl9rZXkiOiIxNmY5NTA3OS0zYzYzLTRlYTctYTM0YS1hZjk2ZmQ1MDE5NmMifQ.3qIQjrYHTwDUxT8vM2_zKewCu03JfujL0YvrPvFBRLEwicNPH_wvn3qvV1XH4Cb4_uM9wFs6awLzDqmboZtPbQ"

    config.headers = {
        ...config.headers,
        'token': window.token,
        "z-ts": now,
        "z-sign": md5(now + "")
    }
    let obj = limitAnswerCount(config.url);
    if (obj) {
        config.headers = {
            ...config.headers,
            ...obj
        }
    }
    return config
},
    err => {
        Promise.reject(err)
    }
)

Http.interceptors.response.use(
    response => {
        let token = response.headers["token"]

        if (!isEmpty(token)) {
            setToken(token)
        }
        return response.data
    },
    err => {
        Promise.reject(err)
    }
)
export const get = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        Http.get(url, { params })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
export const post = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        Http.post(url, params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}



export default Http
